
  import {defineComponent, watch, nextTick, reactive} from "vue";
  import useAddress from "@/hooks/useAddress";
  import Modal from "@/components/UI/Modal";
  import qs from "qs";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  export default defineComponent({
    name: "addressCardOfNei",
    props: {
      addressList: {
        type: Array,
        default: [] as object[]
      },
    },
    setup(props, ctx) {
      let refs: Array<HTMLElement> = [];
      const addressCard = (el: HTMLElement) => {
        refs.push(el);
      }
      watch(() => props.addressList, ()=>{
        const addressData = reactive((props.addressList as any).data || [])
        refs = [];
        if(addressData.length > 0){
          nextTick(() => {
            addressData.map((item: any, index: string | number) => {
              useAddress(refs[index])
            })
          })
        }
      })

      function delAddress(id: string){
        Modal({
          title: "温馨提示",
          content: "确认作废本地址?",
          onConfirm: () => {
            const data = qs.stringify({
              postId:id,
              openInvoiceType:1
            });
            axios.post("/M/AokeNei/setDefaultAddressOfNei", data).then(res => {
              console.log(res)
              Toast({
                type: "success",
                title: "操作成功！",
              });
              window.location.reload()
            }).catch(err => {
              console.log(err)
            })
          },
          onCancel: () => {
            console.log('取消')
          },
        });
      }

      return {
        addressCard,
        delAddress,
      };
    },
  });
