<template>
  <list url="/M/AokeNei/AddressOfNei" :show="true" :showLoading="true">
    <template v-slot:default="data">
      <address-card :addressList="data"></address-card>
    </template>
  </list>
  <footer-address>
    <ul class="footer-nav">
      <li>
        <router-link class="out-login" :to="{ name: 'addAddressOfUser' }">
          <span style="font-size: 21px;margin-right: 10px">+</span>新增地址
        </router-link>
      </li>
    </ul>
  </footer-address>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import AddressCard from "@/components/common/AddressCardOfNei.vue";
import list from "@/components/common/list.vue";


export default defineComponent({
  name: "AddressOfUser",
  components: {
    list,
    AddressCard,
  },
  setup() {
    const list = ref<object>([]);
    return {
      list,
    }
  },
});
</script>

<style lang="scss" scoped>
.out-login {
    margin: 12px 38px;
    width: 300px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    border-radius: 27px;
    display: block;
    background-color: #d64b4b;
    box-shadow: 0px 4.988px 10.013px 0px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    border: none;
    outline: none;
    font-size: 15px;
}
footer-address {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 62px;
  border-top: 1px solid #f4f4f4;
  background-color: #fff;
  z-index: 10;
}

</style>
