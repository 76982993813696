
import { defineComponent, ref } from "vue";
import AddressCard from "@/components/common/AddressCardOfNei.vue";
import list from "@/components/common/list.vue";


export default defineComponent({
  name: "AddressOfUser",
  components: {
    list,
    AddressCard,
  },
  setup() {
    const list = ref<object>([]);
    return {
      list,
    }
  },
});
